import React, { useState } from "react";
import logsvg from "../../images/LOGIN.webp";
import "./login.css";
import pickloadicon from "../../images/pickloadicon.png";
import pickloadiconprt from "../../images/pickloadiconprt.png";
import pickloadtitle from "../../images/pickloadtitle.png";
import icon from "../../images/icon.png";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import person from "../../images/person.png";
import truck from "../../images/truck.png";
import people from "../../images/people.png";

const Login = () => {
  const [phone_num, setPhone] = useState("");
  const [user_type, setUsertype] = useState("user"); //user, delivery_agent
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function switchUser() {
    // e.preventDefault();
    setUsertype("user");
  }
  function switchDA() {
    // e.preventDefault();
    setUsertype("delivery_agent");
  }

  const requestForOTP = async (e) => {
    e.preventDefault();

    if (!phone_num || phone_num.length !== 11) {
      alert("Please input a valid phone number");
      return;
    }
    setActive(true);
    setLoading(true);

    let endpoint;
    let bodyData;
    let phone_no =
      user_type == "user" ? phone_num.slice(1) : "+234" + phone_num.slice(1);
    if (user_type == "user") {
      endpoint =
        "https://serverpickload.wl.r.appspot.com/user_referral/request_for_referral_otp";
      bodyData = {
        phone_no: phone_no,
      };
    } else {
      endpoint =
        "https://serverpickload.wl.r.appspot.com/delivery_agent_referral/request_for_referral_otp";
      bodyData = {
        phone_no: phone_no,
      };
    }

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, /",
        },
      });

      const resultM = await response.json();
      console.log(resultM);
      setLoading(false);

      if (resultM.msg === "Success") {
        navigate("/otp-page", {
          state: { user_type, email: resultM.email, phone_no },
        });
      } else if (resultM.msg === "user not found") {
        alert("No user with this Phone number exists");
        setActive(false);
      } else {
        alert("An unknown error occurred. Please try again");
        setActive(false);
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred. Please try again");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setPhone(e.target.value);
  };

  const isMobile = window.innerWidth < 768;

  return (
    <div className="login-main">
      <div className="login-main-ctn">
        <div className="login-ctn">
          <img src={icon} width="16%" height="12%" alt="icon" />
          <div className="login-props">
            <div className="pickload-title-icon-ctn">
              <img
                src={isMobile ? pickloadiconprt : pickloadicon}
                alt="pickload icon"
                className="pickload-sign-icon"
              />
              <img
                src={pickloadtitle}
                width="30%"
                height="100%"
                alt="title"
                className="pickload-title-icon"
              />
            </div>
            <div className="login-text">
              <h1>
                Sign in as a {""}
                {user_type == "delivery_agent" ? "Delivery Agent" : "User"}
              </h1>
              <p>Sign in to check your rewards.</p>
              <form>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input
                    id="number"
                    type="text"
                    name="number"
                    maxLength={11}
                    value={phone_num}
                    onChange={handleChange}
                  />
                </div>
                <button
                  className="login-btn"
                  onClick={requestForOTP}
                  disabled={active || loading}
                >
                  {loading ? (
                    <span className="spinner"></span>
                  ) : (
                    "Request for OTP"
                  )}
                </button>
              </form>
              <p>
                I am a {""}
                {user_type != "delivery_agent" ? (
                  <span style={{ cursor: "pointer" }} onClick={switchDA}>
                    Delivery Agent
                  </span>
                ) : (
                  <span style={{ cursor: "pointer" }} onClick={switchUser}>
                    User
                  </span>
                )}
              </p>

              {/*  */}
              {/* {user_type == "delivery_agent" ? (
                <div className="referal-ctn">
                  <h1>HOW IT WORKS</h1>
                  <h2>Follow these simple steps</h2>
                  <div className="referal-step-main1">
                    <div className="referal-step">
                      <img src={person} width="38px" height="38px" alt="icon" />
                      <p>
                        Meet your daily and weekly targets to earn exciting
                        rewards.
                      </p>
                    </div>
                    <div className="referal-step">
                      <img src={people} width="38px" height="38px" alt="icon" />
                      <p>
                        Stay focused and track your progress toward each goal.
                      </p>
                    </div>
                    <div className="referal-step">
                      <img src={truck} width="38px" height="38px" alt="icon" />
                      <p>
                        Sit back, relax, and wait for your daily and weekly
                        rewards to arrive.
                      </p>
                    </div>
                    <a href="/Termsandconditions">Terms & Conditions apply</a>{" "}
                    <br />
                  </div>
                </div>
              ) : (
                <div className="referal-ctn">
                  <h1>HOW IT WORKS</h1>
                  <h2>Follow these simple steps</h2>
                  <div className="referal-step-main1">
                    <div className="referal-step">
                      <img src={person} width="38px" height="38px" alt="icon" />
                      <p>
                        {" "}
                        Invite the required number of people to download the
                        Pickload user app
                      </p>
                    </div>
                    <div className="referal-step">
                      <img src={people} width="38px" height="38px" alt="icon" />
                      <p>
                        Get those people to sign up using your unique referral
                        code and complete the required number of deliveries
                      </p>
                    </div>
                    <div className="referal-step">
                      <img src={truck} width="38px" height="38px" alt="icon" />
                      <p>
                        Sit back, relax, and wait for your rewards to arrive.
                      </p>
                    </div>
                    <a href="/Termsandconditions">Terms & Conditions apply</a>{" "}
                    <br />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="login-img">
          <img src={logsvg} width="100%" height="100%" alt="icon" />
        </div>
      </div>
      <div className="login-footer">
        <Footer />
      </div>
    </div>
  );
};

export default Login;
