import React from 'react';
import { useLocation } from "react-router-dom";
import "./referral.css";
import Footer from '../../component/footer/Footer';
import person from "../../images/person.png";
import people from "../../images/people.png";
import copy from "../../images/copy.png";
import blackcopy from "../../images/blackcopy.png";


const RewardArrival = () => {
    const location = useLocation();
    const { referral_code } = location.state;
    return (
        <div>
            <div className="referal-top">
                <h1>Refer & Earn!</h1>
                <h2>Stand a chance to win, cash, data or airtime bonuses</h2>
            </div>
            <div className='refer-reward'>
                <div className="referal-ctn">
                    <h1>HOW IT WORKS</h1>
                    <h2>Follow these simple steps</h2>
                    <div className="referal-step-main">
                        <div className="referal-step">
                            <img src={person} width="38px" height="38px" alt="icon" /> <p>

                                Invite the required number of people to download the
                                Pickload user app
                            </p>
                        </div>
                        <div className="referal-step">
                            <img src={people} width="38px" height="38px" alt="icon" />
                            <p>
                                Get those people to sign up using your unique referral
                                code and complete the required number of deliveries</p>
                        </div>
                        <div className="referal-step">
                            <img src={blackcopy} width="38px" height="38px" alt="icon" />
                            <p>Sit back, relax, wait to get your referral reward</p>
                        </div>
                        <div className="referal-code-ctn">
                            <p>Your unique referral code</p>
                            <div className="referal-code">
                                <p>{referral_code}</p>
                                <div className="refer-share"  onClick={() => {
                                        navigator.clipboard.writeText(referral_code)
                                    }
                                    } >
                                    <img src={copy} width="20px" height="20px" alt="icon"/>
                                    <p>Copy code</p>
                                </div>
                            </div>
                        </div>
                        <a href="/Termsandconditions">Terms & Conditions apply</a> <br />
                    </div>
                </div>
                <div className="congratulations-page">
                    <div className="congratulations-card">
                        <h1>Hold On!</h1>
                        <p>Your reward is on its way. <br /> Please stay tuned!</p>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default RewardArrival