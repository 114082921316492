import React, { useState, useEffect, useRef } from "react";
import "./chat.css";
import sendicon from "../../images/sendicon.png";
// import { userContext } from "../../../Shadow/Pages/Contexts/RiderContext";
import {
  collection,
  query,
  onSnapshot,
  doc,
  setDoc,
  updateDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import dayjs from "dayjs";
import { db } from "../../utils/firebase";
import audioFile2 from "../audio/beep2.wav";
import pickloadicon from "../../images/pickloadicon.png";
const audio2 = new Audio(audioFile2);

const Guest = ({ closePopup }) => {
  // const navigate = useNavigate();
  const [convId, setConvId] = useState("");
  const [messageList, setMessageList] = useState([]);
  const bottomRef = useRef(null);
  const [token, setToken] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [new_conv, setNew_conv] = useState(undefined);
  const [content, setContent] = useState("");
  const [convo, setConvo] = useState("");
  const [img] = useState("");
  // const [display, setDisplay] = useState("");
  const [loadM, setLoadM] = useState(false);
  const [loading, setLoading] = useState(false);
  const conversations = sessionStorage.getItem("convo_id");
  const Realtoken = sessionStorage.getItem("guest_token");
  const converse = sessionStorage.getItem("convo");


 
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Update screen size on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);




  const Messager = (item, i) => {
    // console.log(item?.content);
    const user_id = `guest_${convId}`;
    // let DATE = {};
    const TimeConverter = (props) => {
      // console.log(props)
      const timer = item?.timestamp
        ? dayjs(props.value.seconds * 1000).format("hh:mm a")
        : "...";
      return timer;
    };
   
    let cname = "";
    if (item.sender_id === user_id) {
      cname = "outgoing-msgs";
    } else {
      cname = "incoming-msgs";
    }

    console.log(JSON.stringify(item));

    return (
      <li
        key={i}
        ref={bottomRef}
        className={cname === "outgoing-msgs" ? "sent" : "received"}
      >
        <div className="message-bubble">
          {item.message_type === "image" ? (
            <>
              <img
                src={item?.content}
                width="100px"
                height=" 100px"
                style={{ marginBottom: "5px", maxWidth: "100px", marginLeft: "12rem" }}
                alt="icon"
              />
              <h2 className="message-time">
                <span>
                  <TimeConverter value={item?.timestamp} />
                </span>
              </h2>
            </>
          ) : (
            <>
              {cname !== "outgoing-msgs" && (
                <div className="chat-profile-pic-container">
                  <img
                    src={pickloadicon}
                    width="45px"
                    height="45px"
                    alt="icon"
                    className="chat-profile-pic"
                  />
                </div>
              )}
              <p className="messenger">{item?.content}</p>
              <h2 className="message-time">
                <span>
                  <TimeConverter value={item?.timestamp} />
                </span>
              </h2>
            </>
          )}
        </div>
      </li>
    );
  };

  function my_firebase_serverTimestamp_mimick() {
    const now = new Date();
    const seconds = Math.floor(now.getTime() / 1000);
    const nanoseconds = now.getMilliseconds() * 1000000;

    return {
      seconds: seconds,
      nanoseconds: nanoseconds,
    };
  }

  function send_first_msg() {
    const list = [];
    let default_msg = {
      timestamp: my_firebase_serverTimestamp_mimick(),
      message_type: "text",
      which_user: "user",
      who_sent: "admin",
      sender_id: "default_admin_id",
      conv_id: convId,
      sender_img: "a",
      file_name: "a",
      sender_name: "Admin",
      content:
        "Welcome to Pickload Logistics. We're happy to have you with us. How can we assist you today?",
    };
    list.push(default_msg);

    setMessageList(list);

    console.log(default_msg);
  }

  useEffect(() => {
    const q = query(collection(db, "hf_collection", convo, convo));
    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      const list = [];
      QuerySnapshot.forEach((doc) => {
        list.push(doc?.data());
        setMessageList(list);
      });
      if (list.length > 0) {
        setNew_conv(false);
      }
    });

    if (isLoaded === false) {
      unsubscribe();
    }
  }, [loadM]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messageList]);

  useEffect(() => {
    createToken();
    // console.log(token);
    let convo_id;
    if (converse === null) {
      convo_id = Date.now().toString();
      sessionStorage.setItem("convo_id", JSON.stringify(convo_id));
      setConvId(convo_id);
      // setIsLoaded(true);
      setConvo("a");
      setNew_conv(true);
    } else {
      setConvId(JSON.parse(conversations));
      setIsLoaded(true);
      setConvo(converse);
      setNew_conv(false);
      setLoadM(true);
      // setToken(Realtoken);
    }
    send_first_msg();
  }, []);

  const createToken = async (e) => {
    if (Realtoken == null) {
      try {
        const res = await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/generate_guest_user_token",
          {
            method: "POST",
            body: JSON.stringify({}),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );

        const data = await res.json();

        console.log(data);
        setIsLoaded(true);
        setToken(data?.token);
        sessionStorage.setItem("guest_token", data?.token);
      } catch (error) {
        // console.log(error);
        // const err = error
      }
    } else {
      setIsLoaded(true);
    }
  };

  const SendMessage = async (e) => {
    // console.log(token);
    e.preventDefault();
    if (content.trim() === "") {
      return;
    }
    // console.log(token, `Guest_${convId}`, new_conv, content, `guest_${convId}`);
    if (new_conv === true || convId === "a") {
      const contentToDB = content;
      setContent("");

      try {
        const res = await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/send_message",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              sender_name: `Guest_${convId}`,
              new_conv: true,
              sender_img: "a",
              content: contentToDB,
              who_sent: "user",
              which_user: "guest",
              user_id: `guest_${convId}`,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const data = await res.json();

        if (data.msg === "Message sent") {
          let conv_id = data?.message.conversation_id;
          setConvo(data?.message.conversation_id);
          setNew_conv(false);
          setIsLoaded(true);
          sessionStorage.setItem("convo", data?.message.conversation_id);

          await setDoc(
            doc(db, "hf_collection", conv_id, conv_id, `${Date.now()}`),
            {
              content: contentToDB,
              sender_id: `guest_${convId}`,
              sender_img: "a",
              sender_name: `Guest_${convId}`,
              timestamp: serverTimestamp(),
              which_user: "guest",
              who_sent: "user",
              message_type: img ? "image" : "text",
              file_name: "a",
            }
          );
          audio2.play();
          setLoadM(true);

          const notifyRef = doc(db, "admin_notifiers", "hf_messages");
          await updateDoc(notifyRef, {
            messages_count: increment(1),
          });
          const badgeDocRef = doc(db, "hf_collection", conv_id);
          await setDoc(badgeDocRef, {
            unread_msg_count: 1,
          });
        } else {
          // console.log(" did not send message");
        }
      } catch (error) {
        // console.log(error);
        // const err = error
      }
    } else {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        const contentToDB = content;
        setLoading(true);

        setContent("");
        await setDoc(doc(db, "hf_collection", convo, convo, `${Date.now()}`), {
          content: contentToDB,
          sender_id: `guest_${convId}`,
          sender_img: "a",
          sender_name: `Guest_${convId}`,
          timestamp: serverTimestamp(),
          which_user: "guest",
          who_sent: "user",
          message_type: "text",
        });

        audio2.play();
        //   setIsLoaded(true);
        setLoading(false);
        setLoadM(true);

        const notifyRef = doc(db, "admin_notifiers", "hf_messages");
        await updateDoc(notifyRef, {
          messages_count: increment(1),
        });

        const badge = doc(db, "conversations", convo);
        await updateDoc(badge, {
          unread_user_message_count: increment(1),
        });

        const response = await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/send_message",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              sender_name: `Guest_${convId}`,
              new_conv: false,
              sender_img: "a",
              content: contentToDB,
              who_sent: "user",
              which_user: "guest",
              user_id: `guest_${convId}`,
              conv_id: convo,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const res = await response.json();
        if (response.status === 200) {
          console.log("sent message two");
        } else {
          console.log("did not send message two");
        }
      } else if (!e.code) {
        setLoading(true);
        const contentToDB = content;
        setContent("");
        await setDoc(doc(db, "hf_collection", convo, convo, `${Date.now()}`), {
          content: contentToDB,
          sender_id: `guest_${convId}`,
          sender_img: "a",
          sender_name: `Guest_${convId}`,
          timestamp: serverTimestamp(),
          which_user: "guest",
          who_sent: "user",
          message_type: "text",
        });

        audio2.play();
        //   setIsLoaded(true);
        setLoading(false);
        setLoadM(true);

        const notifyRef = doc(db, "admin_notifiers", "hf_messages");
        await updateDoc(notifyRef, {
          messages_count: increment(1),
        });

        const badge = doc(db, "conversations", convo);
        await updateDoc(badge, {
          unread_user_message_count: increment(1),
        });

        const response = await fetch(
          "https://serverpickload.wl.r.appspot.com/help_feedback/send_message",
          {
            method: "POST",
            body: JSON.stringify({
              token: token,
              sender_name: `Guest_${convId}`,
              new_conv: false,
              sender_img: "a",
              content: contentToDB,
              who_sent: "user",
              which_user: "guest",
              user_id: `guest_${convId}`,
              conv_id: convo,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );
        const res = await response.json();
        if (response.status === 200) {
          console.log("sent message two");
        } else {
          console.log("did not send message two");
        }
      }
    }
  };

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-based (0 = January)
  const currentDay = currentDate.getDate();

  if (isLoaded === true) {
    return (
      <div className={`popup ${isMobile ? 'fullscreen' : ''}`}>
        <div className="chat-page">
          <div className="chat-section">
            <h1 className="chat-title">Chat with Pickload</h1>
            <button className="chat-close" onClick={closePopup}>X</button>
            <div ref={bottomRef}>
              <ul className="messages-wrapper">
                <div className="conversation-design">
                  <h1 className="conversation-date">
                    {currentDay}-{currentMonth}-{currentYear}
                  </h1>
                </div>
                {messageList?.map((item, i) => Messager(item, i))}
              </ul>

              {/* textfield */}
              <form className="form-message" onSubmit={SendMessage}>
                <textarea
                  role="textbox"
                  placeholder="Type Message here"
                  rows="1"
                  cols="20"
                  typeof="submit"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); 
                      SendMessage(e); 
                    }
                  }}
                />
                <div className="chat-icons">
                  <div className="send-message">
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        width: "45px",
                      }}
                      type="submit"
                      disabled={loading}
                      id={content.trim() === "" ? "disabled" : ""}
                    >
                      <img src={sendicon} onClick={SendMessage} className="send-message-icon" alt="icon" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (isLoaded === false) {
    return (
      <div className="loading-spinner-overlay">
        <div className="loading-spinner"></div>
      </div>
    );
  }
};
export default Guest;
