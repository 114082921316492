import React, { useState } from "react";
import person from "../../images/person.png";
import truck from "../../images/truck.png";
import people from "../../images/people.png";
import Footer from "../../component/footer/Footer";
import congrats from "../../images/congrats.png";
import data from "../../images/data.png";
import { useLocation, useNavigate } from "react-router-dom";

const DataReward = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [phone_no, setPhoneNo] = useState("");
  const [service_provider, setServiceProvider] = useState("");
  const location = useLocation();
  const { token, referral_code } = location.state;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // checks
    if (!service_provider || !phone_no) {
      alert("Please fill in all details");
    }
    if (phone_no.length !== 11) {
      alert("Phone number must be 11 digits");
    }

    setLoading(true);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/user_referral/set_referral_reward_details",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            phone_recharge: phone_no,
            service_provider,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, /",
          },
        }
      );

      await response.json();
      setLoading(false);

      if (response.status === 200) {
        setShowMessage(true); // Show the congratulatory message
        setTimeout(() => {
          navigate("/reward-arrival");
        }, 2000); // Auto-close modal and message after 2 seconds
      } else {
        alert("An error occurred. Please try again");
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred. Please try again");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="referal-top">
        <h1>Refer & Earn!</h1>
        <h2>Stand a chance to win, cash, data or airtime bonuses</h2>
      </div>
      <div className="refer-reward">
        <div className="referal-ctn">
          <h1>HOW IT WORKS</h1>
          <h2>Follow these simple steps</h2>
          <div className="referal-step-main1">
            <div className="referal-step">
              <img src={person} width="38px" height="38px" alt="icon" />
              <p>
                {" "}
                Invite the required number of people to download the Pickload
                user app
              </p>
            </div>
            <div className="referal-step">
              <img src={people} width="38px" height="38px" alt="icon" />
              <p>
                Get those people to sign up using your unique referral code and
                complete the required number of deliveries
              </p>
            </div>
            <div className="referal-step">
              <img src={truck} width="38px" height="38px" alt="icon" />
              <p>Sit back, relax, and wait for your rewards to arrive.</p>
            </div>
            <a href="/Termsandconditions">Terms & Conditions apply</a> <br />
          </div>
        </div>
        <div className="reward-congrats">
          <img src={congrats} width="70%" height="70%" alt="congratulations" />
          <p>You just qualified for our daily bonus</p>
          <img src={data} width="20%" height="20%" alt="congratulations" />
          <h2>Data reward</h2>
          <button onClick={() => setIsOpen(true)}>Claim Reward</button>
          {isOpen && (
            <div className="reward-modal">
              <div className="reward-modal-content">
                <span className="close" onClick={() => setIsOpen(false)}>
                  &times;
                </span>
                <h2>User Record Details</h2>
                <form onSubmit={handleSubmit} className="reward-form">
                  <label>Phone Number to be recharged</label>
                  <input
                    type="number"
                    name="phonenumber"
                    maxLength={11}
                    value={phone_no}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                  <label>Service Provider</label>
                  <input
                    type="text"
                    name="accountNumber"
                    value={service_provider}
                    onChange={(e) => setServiceProvider(e.target.value)}
                  />
                  <button
                    type="submit"
                    className="reward-btn"
                    disabled={loading}
                  >
                    {loading ? <span className="spinner"></span> : "Submit"}
                  </button>
                </form>
                {showMessage && (
                  <div className="congratulations">
                    Details Submitted. Reward on the way!
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DataReward;
