import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import weekRangeGetter, { getWeekNumber } from "./weekRanger";
import dayjs from "dayjs";
import { ClipLoader } from "react-spinners";
import "./agent_referral.css";
import person from "../../images/person.png";
import truck from "../../images/truck.png";
import people from "../../images/people.png";
import Footer from "../../component/footer/Footer";
import congrats from "../../images/congrats.png";
import wallet from "../../images/wallet.png";
import airtime from "../../images/airtime.png";
import data from "../../images/data.png";

const AgentReferral = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);
  const location = useLocation();
  const { token } = location.state;
  const [isOpen, setIsOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setShowMessage(true);
  //   setTimeout(() => {
  //     setIsOpen(false);
  //     setShowMessage(false);
  //   }, 2000);
  // };

  //   console.log(`----------> ${token}`);

  const [cname1, setCname1] = useState("week1");
  const [cname2, setCname2] = useState("week2");
  const [cname3, setCname3] = useState("week2");
  const [cname4, setCname4] = useState("week2");
  const [cname5, setCname5] = useState("week2");
  const [cname6, setCname6] = useState("week2");
  const [Dweek, setDWeek] = useState(1);
  const [day, setDay] = useState(Number.parseInt(dayjs().day()));
  const [month, setMonth] = useState(Number.parseInt(dayjs().month()));
  const [year, setYear] = useState(Number.parseInt(dayjs().year()));
  const [date, setDate] = useState(dayjs(Date.now()).format("YYYY-MM"));
  const [Cweek, setCweek] = useState(1);

  const [selectedPaymentType, setSelectedPaymentType] = useState("daily");

  const setPageOption = (option) => {
    setSelectedPaymentType(option);

    //doing all of this so that the date can reset to the current day when the option is changed
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");

    setYear(year);
    setMonth(month);
    setDay(day);

    setSelectedDate(getCurrentDate()); // Set the selected date to the current date for Day, Month & Year
    setDate(dayjs(Date.now()).format("YYYY-MM")); // Set the selected date to the current date for Month & Year
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(getCurrentDate()); // Default to current date

  useEffect(() => {
    // console.log(`----------> REFRESHING`);
    fetchData();
  }, [selectedPaymentType, Cweek, date, selectedDate]);

  const fetchData = async () => {
    try {
      setIsLoaded(false);

      let endpoint =
        selectedPaymentType === "daily"
          ? "https://serverpickload.wl.r.appspot.com/delivery_agent_referral/view_daily_qualified_agent"
          : "https://serverpickload.wl.r.appspot.com/delivery_agent_referral/view_weekly_qualified_agent";
      let endpointData =
        selectedPaymentType === "daily"
          ? {
            token: token,
            day: Number.parseInt(day),
            month: Number.parseInt(month),
            year: Number.parseInt(year),
          }
          : {
            token: token,
            week: Number.parseInt(Cweek),
            month: Number.parseInt(month),
            year: Number.parseInt(year),
          };
      const response = await fetch(endpoint, {
        method: "POST",

        body: JSON.stringify(endpointData),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
      });
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);

      setResult(resultM);
      setRewardType("cash");
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  //   console.log(`----------> ${Cweek}`);

  //   const [selectedDate, setSelectedDate] = useState("");
  const [noti_data, setNotiData] = useState("");
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const weekNo = getWeekNumber(year, month, new Date().getDate());
    switch (weekNo) {
      case 1:
        handleClick("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[0]
        );
        break;
      case 2:
        handleClick1("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[1]
        );
        break;
      case 3:
        handleClick2("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[2]
        );
        break;
      case 4:
        handleClick3("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[3]
        );
        break;
      case 5:
        handleClick4("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[4]
        );
        break;
      case 6:
        handleClick6("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[5]
        );
        break;
    }
  }, []);

  const handle_MY_Calender = (e) => {
    const newDate = dayjs(e.target.value).format("YYYY-MM");
    const yearM = Number.parseInt(newDate.slice(0, 4));
    const monthM = Number.parseInt(newDate.slice(5, 7)) - 1;
    setDate(newDate);
    setMonth(monthM);
    setYear(yearM);
    weekRangeGetter(monthM, yearM);
    // console.log(newDate, yearM, monthM);
  };
  const handle_DMY_Calender = (e) => {
    const dateValue = e.target.value; // Format: YYYY-MM-DD
    setSelectedDate(dateValue);

    // Extract day, month, and year
    if (dateValue) {
      const [year, month, day] = dateValue.split("-");

      setYear(year);
      setMonth(month);
      setDay(day);
      //   console.log(`Selected Date: Day: ${day}, Month: ${month}, Year: ${year}`);
    }
  };

  const handleClassName1 = () => {
    setCname1("week1");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[0]
    );
  };
  const handleClassName2 = () => {
    setCname1("week2");
    setCname2("week1");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[1]
    );
  };
  const handleClassName3 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week1");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[2]
    );
  };
  const handleClassName4 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week1");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[3]
    );
  };
  const handleClassName5 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week1");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[4]
    );
  };
  const handleClassName6 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week1");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[5]
    );
  };

  const handleClick = (e) => {
    // console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      setCweek(1);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      setCweek(1);
    }
  };

  const handleClick1 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      setCweek(2);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      setCweek(2);
    }
  };

  const handleClick2 = (e) => {
    //console.log(e);
    if (e === "auto") {
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      setCweek(3);
    } else {
      e.preventDefault();
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      setCweek(3);
    }
  };

  const handleClick3 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      setCweek(4);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      setCweek(4);
    }
  };

  const handleClick4 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      setCweek(5);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      setCweek(5);
    }
  };
  const handleClick6 = (e) => {
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      setCweek(6);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      setCweek(6);
    }
  };

  const initialData = [
    {
      name: "Jude Ozoh",
      phone: "0812843283209",
      reward: "Airtime",
      rewardDetails: {
        fullName: "Jude Ozoh",
        bankAccount: "2210908302",
        bank: "First Bank",
      },
      rewardStatus: "Paid",
    },
    {
      name: "Nino Ola",
      phone: "0812843283209",
      reward: "Airtime",
      rewardDetails: {
        fullName: "Nino Ola",
        bankAccount: "2210908302",
        bank: "Access Bank",
      },
      rewardStatus: "Not Paid",
    },
  ];

  const [data2, setData2] = useState(initialData);
  const [modalData, setModalData] = useState({
    isOpen: false,
    message: "",
    index: null,
  });
  const [selectedReward, setSelectedReward] = useState(null);

  // const handleRewardStatusClick = (index) => {
  //   const item = data2[index];
  //   const message = `Are you sure you want to reward ${item.name}?`;
  //   // const message = `Are you sure you want to reward ${item.name} with "${choosenReward}" Reward?`;

  //   setModalData({ isOpen: true, message, index });
  //   setSelectedReward(item.reward); // Set current reward for editing
  // };

  const closeModal = () =>
    setModalData({ isOpen: false, message: "", index: null });

  const confirmAction = () => {
    if (modalData.index !== null) {
      setData2((prevData) =>
        prevData.map((item, index) =>
          index === modalData.index
            ? { ...item, reward: selectedReward, rewardStatus: "Paid" }
            : item
        )
      );
    }
    closeModal();
  };

  const [loading, setLoading] = useState(false);
  //data and airtime
  const [phone_no, setPhoneNo] = useState("");
  const [service_provider, setServiceProvider] = useState("");

  //cash
  const [ref_bank, setRefBank] = useState("");
  const [ref_account_no, setRefAccountNo] = useState("");
  const [ref_account_name, setRefAccountName] = useState("");
  const [reward_type, setRewardType] = useState("");

  const handleSubmitRewardDetails = async (e) => {
    e.preventDefault();
    // checks

    if (reward_type == "cash") {
      if (!ref_account_no || !ref_bank || !ref_account_no) {
        alert("Please fill in all details");
        return;
      }
    } else {
      if (!service_provider || !phone_no) {
        alert("Please fill in all details");
        return;
      }
      if (phone_no.length !== 11) {
        alert("Phone number must be 11 digits");
        return;
      }
    }

    // console.log(
    //   ref_account_name,
    //   ref_account_no,
    //   ref_bank,
    //   service_provider,
    //   phone_no,
    //   reward_type
    // );

    setLoading(true);
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/delivery_agent_referral/set_referral_reward_details",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            phone_recharge: phone_no,
            service_provider: service_provider,
            ref_account_no: ref_account_no,
            ref_bank: ref_bank,
            ref_account_name: ref_account_name,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, /",
          },
        }
      );
      const resultM = await response.json();

      setLoading(false);

      if (resultM.msg === "Success") {
        setShowMessage(true);
        setTimeout(() => {
          setIsOpen(false);
          window.location.reload();
        }, 2000);
      } else {
        alert("An error occurred. Please try again");
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred. Please try again");
      setLoading(false);
    }
  };

  return (
    <div className="referal-table">
      <div className="referal-btn-ctn1">
        <button
          onClick={() => setPageOption("daily")}
          className={`agent-ref-payment-type ${selectedPaymentType === "daily" ? "active" : ""
            }`}
          style={{
            backgroundColor:
              selectedPaymentType === "daily"
                ? "rgba(31, 170, 8, 1)"
                : "transparent",
            color:
              selectedPaymentType === "daily"
                ? "rgba(255, 255, 255, 1)"
                : "rgba(31, 170, 8, 1)",
            padding: "0 2.3rem",
            cursor: "pointer",
            marginRight: "1rem",
            borderRadius: "3px",
            transition: "all 0.3s ease",
          }}
        >
          Daily
        </button>
        <button
          onClick={() => setPageOption("weekly")}
          className={`agent-ref-payment-type ${selectedPaymentType === "weekly" ? "active" : ""
            }`}
          style={{
            backgroundColor:
              selectedPaymentType === "weekly"
                ? "rgba(31, 170, 8, 1)"
                : "transparent",
            color:
              selectedPaymentType === "weekly"
                ? "rgba(255, 255, 255, 1)"
                : "rgba(31, 170, 8, 1)",
            padding: "0 2.3rem",
            cursor: "pointer",
            marginRight: "1rem",
            borderRadius: "3px",
            transition: "all 0.3s ease",
          }}
        >
          Weekly
        </button>
      </div>
      <div className="payment-content">
        {selectedPaymentType === "daily" && (
          <div>
            <div className="payment-history">
              <div className="payment-calendar-container">
                <input
                  type="date"
                  name="schedule"
                  className="payment-calendar1"
                  value={selectedDate}
                  onChange={handle_DMY_Calender}
                />
              </div>
            </div>
          </div>
        )}
        {selectedPaymentType === "weekly" && (
          <div>
            <div className="payment-history">
              <div className="payment-calender-container">
                <input
                  type="month"
                  name="schedule"
                  min="2021-12-31"
                  max="2043-01-01"
                  className="payment-calender"
                  value={date}
                  onChange={handle_MY_Calender}
                />
              </div>
              <div className="payment-week">
                <div className="week1date">
                  <div className={cname1} onClick={handleClick}>
                    WEEK 1
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[0]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname2} onClick={handleClick1}>
                    WEEK 2
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[1]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname3} onClick={handleClick2}>
                    WEEK 3
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[2]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname4} onClick={handleClick3}>
                    WEEK 4
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[3]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname5} onClick={handleClick4}>
                    WEEK 5
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[4]}
                  </p>
                </div>
                {weekRangeGetter(month, year)[5] ? (
                  <div className="week1date">
                    <div className={cname6} onClick={handleClick6}>
                      WEEK 6
                    </div>
                    <p className="week-duration">
                      {weekRangeGetter(month, year)[5]}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="pick-reward-web">
        <div className="refer-reward">
          <div className="referal-ctn">
            <h1>HOW IT WORKS</h1>
            <h2>Follow these simple steps</h2>
            <div className="referal-step-main1">
              <div className="referal-step2">
                <img src={person} width="38px" height="38px" alt="icon" />
                <p>
                  {" "}
                  Meet your daily and weekly targets to earn exciting rewards.
                </p>
              </div>
              <div className="referal-step2">
                <img src={people} width="38px" height="38px" alt="icon" />
                <p>Stay focused and track your progress toward each goal.</p>
              </div>
              <div className="referal-step2">
                <img src={truck} width="38px" height="38px" alt="icon" />
                <p>
                  Sit back, relax, and wait for your daily and weekly rewards
                  to arrive.
                </p>
              </div>
              <a href="/Termsandconditions">Terms & Conditions apply</a>{" "}
              <br />
            </div>
          </div>
          <div>
            {!isLoaded ? (
              <div style={{ textAlign: "center" }}>
                <ClipLoader color={"#1FAA08"} size={100} />
              </div>
            ) : result.msg === "Success" ? (
              <>
                <div className="reward-congrats">
                  <img
                    src={congrats}
                    width="70%"
                    height="70%"
                    alt="congratulations"
                    className="congrat-img"
                  />
                  <p>
                    You just qualified for our {selectedPaymentType} bonus
                  </p>
                  <img
                    src={
                      reward_type == "cash"
                        ? wallet
                        : reward_type == "airtime"
                          ? airtime
                          : data
                    }
                    width="20%"
                    height="20%"
                    alt="congratulations"
                    className="wallet-img"
                  />
                  <h2>
                    {reward_type.charAt(0).toUpperCase() +
                      reward_type.slice(1)}{" "}
                    reward
                  </h2>
                  <button
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Claim Reward
                  </button>
                  {isOpen && (
                    <div className="reward-modal">
                      <div className="reward-modal-content">
                        <span
                          className="close"
                          onClick={() => setIsOpen(false)}
                        >
                          &times;
                        </span>
                        <h2>Agent Record Details</h2>
                        <form
                          // onSubmit={handleSubmit}
                          className="reward-form"
                        >
                          {reward_type == "cash" ? (
                            <>
                              <label>Your Account Name</label>
                              <input
                                type="text"
                                name="ref_account_name"
                                value={ref_account_name}
                                onChange={(e) =>
                                  setRefAccountName(e.target.value)
                                }
                              />
                              <label>Bank Account Number</label>
                              <input
                                type="text"
                                name="ref_account_no"
                                value={ref_account_no}
                                onChange={(e) =>
                                  setRefAccountNo(e.target.value)
                                }
                              />
                              <label>Bank Name</label>
                              <input
                                type="text"
                                name="ref_bank"
                                value={ref_bank}
                                onChange={(e) => setRefBank(e.target.value)}
                              />
                            </>
                          ) : (
                            <>
                              <label>Phone Number to be recharged</label>
                              <input
                                type="number"
                                name="phone_no"
                                maxLength={11}
                                value={phone_no}
                                onChange={(e) => setPhoneNo(e.target.value)}
                              />
                              <label>Service Provider</label>
                              <input
                                type="text"
                                name="service_provider"
                                value={service_provider}
                                onChange={(e) =>
                                  setServiceProvider(e.target.value)
                                }
                              />
                            </>
                          )}

                          <button
                            type="submit"
                            className="reward-btn"
                            disabled={loading}
                            onClick={handleSubmitRewardDetails}
                          >
                            {loading ? (
                              <span className="spinner"></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                        </form>
                        {showMessage && (
                          <div className="congratulations">
                            Details Submitted. Reward on the way!
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : result.msg === "not qualified for this day" ||
              result.msg === "not qualified for this week" ? (
              <p style={{ textAlign: "center" }}>
                You haven't qualified for any rewards yet
              </p>
            ) : (
              <p style={{ textAlign: "center" }}>
                Check your internet connection and try again
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentReferral;
