import React, { useState } from "react";
import person from "../../images/person.png";
import people from "../../images/people.png";
import Footer from "../../component/footer/Footer";
import congrats from "../../images/congrats.png";
import wallet from "../../images/wallet.png";
import copy from "../../images/copy.png";
import { useLocation } from "react-router-dom";
import blackcopy from "../../images/blackcopy.png";

const Reward = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const location = useLocation();
    const {referral_code} = location.state;
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowMessage(true); // Show the congratulatory message
        setTimeout(() => {
            setIsOpen(false);
            setShowMessage(false);
        }, 2000); // Auto-close modal and message after 2 seconds
    };

  return (
    <div>
      <div className="referal-top">
        <h1>Refer & Earn!</h1>
        <h2>Stand a chance to win, cash, data or airtime bonuses</h2>
      </div>
      <div className="refer-reward">
        <div className="referal-ctn">
          <h1>HOW IT WORKS</h1>
          <h2>Follow these simple steps</h2>
          <div className="referal-step-main">
            <div className="referal-step">
              <img src={person} width="38px" height="38px" alt="icon" />{" "}
              <p>
                Invite the required number of people to download the Pickload
                user app
              </p>
            </div>
            <div className="referal-step">
              <img src={people} width="38px" height="38px" alt="icon" />
              <p>
                Get those people to sign up using your unique referral code and
                complete the required number of deliveries
              </p>
            </div>
            {/* <div className="referal-step">
              <img src={truck} width="38px" height="38px" alt="icon" />
              <p>Get them to request for and complete at least 10 daily delivery orders on the app</p>
            </div> */}
            <div className="referal-step">
              <img src={blackcopy} width="38px" height="38px" alt="icon" />
              <p>Sit back, relax, wait to get your referral reward</p>
            </div>
            <div className="referal-code-ctn">
              <p>Your unique referral code</p>
              <div className="referal-code">
                <p>{referral_code}</p>
                <div className="refer-share">
                  <img
                    src={copy}
                    width="20px"
                    height="20px"
                    alt="icon"
                    onClick={() => {
                      navigator.clipboard.writeText(referral_code);
                    }}
                  />
                  <p>Copy code</p>
                </div>
              </div>
            </div>
            <a href="/Termsandconditions">Terms & Conditions apply</a> <br />
          </div>
        </div>
        <div className="reward-congrats">
          <img src={congrats} width="70%" height="70%" alt="congratulations" />
          <p>You just qualified for our daily bonus</p>
          <img src={wallet} width="20%" height="20%" alt="congratulations" />
          <h2>Cash reward</h2>
          <button onClick={() => setIsOpen(true)}>Claim Reward</button>
          {isOpen && (
            <div className="reward-modal">
              <div className="reward-modal-content">
                <span className="close" onClick={() => setIsOpen(false)}>
                  &times;
                </span>
                <h2>User Record Details</h2>
                <form onSubmit={handleSubmit} className="reward-form">
                  <label>FullName</label>
                  <input type="text" name="name" />
                  <label>Account number</label>
                  <input type="text" name="accountNumber" />
                  <label>Bank</label>
                  <input type="text" name="bank" />
                  <button type="submit" className="reward-btn">
                    Submit
                  </button>
                </form>
                {showMessage && (
                  <div className="congratulations">
                    Details Submitted. Reward on the way!
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reward;
